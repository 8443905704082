import { Injectable, isDevMode } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  Observable,
  filter,
  first,
  interval,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { User } from "src/app/api/users/model";
import { UsersApiActions } from "src/app/api/users/state/users.actions";
import { selectUserById } from "src/app/api/users/state/users.selectors";
import { UsersService } from "src/app/api/users/users.service";
import { JWTTokenPayload } from "./model";
import { AuthApiActions } from "./state/auth.actions";
import {
  isAuthenticated,
  selectPayload,
  selectToken,
} from "./state/auth.selectors";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private readonly store: Store,
    private readonly usersService: UsersService
  ) {}

  readonly user$ = this.store.select(selectPayload).pipe(
    switchMap((payload) =>
      !!payload
        ? (this.store.select(selectUserById(payload.sub)) as Observable<User>)
        : of(null)
    ),
    map((user) => (user && "regulationaccept" in user ? user : null))
  );
  readonly isAuthenticated$ = this.store.select(isAuthenticated);

  private readonly returnTokenEnabled = new BehaviorSubject<boolean>(false);
  readonly returnTokenEnabled$ = this.returnTokenEnabled
    .asObservable()
    .pipe(startWith(this.returnTokenEnabled.value));

  loadConfiguration() {
    const token = localStorage.getItem("mpdm-token");
    if (token) {
      // TODO: nowy endpoint walidujący token na bazie
      this.store.dispatch(AuthApiActions.userAuthenticationRestore({ token }));
      this.isAuthenticated$
        .pipe(
          first(),
          filter((auth) => auth),
          switchMap(() => this.fetchUser().pipe(first()))
        )
        .subscribe();
    }
    this.heartbeat().subscribe();
  }

  getToken(): Observable<string> {
    return this.store.select(selectToken);
  }

  getTokenPayload(): Observable<JWTTokenPayload> {
    return this.store.select(selectPayload);
  }

  setToken(token: string): void {
    this.store.dispatch(
      AuthApiActions.userAuthenticationSuccess({ response: { token } })
    );
    this.fetchUser().pipe(first()).subscribe();
    localStorage.setItem("mpdm-token", token);
  }

  logout(): void {
    this.store.dispatch(AuthApiActions.userLogout({}));
    localStorage.removeItem("mpdm-token");
  }

  private fetchUser() {
    return this.store.select(selectPayload).pipe(
      filter((payload) => !!payload),
      tap((payload) =>
        this.store.dispatch(UsersApiActions.userFetchByID({ id: payload.sub }))
      )
    );
  }

  private heartbeat() {
    return interval(60 * 1000).pipe(
      filter(() => !isDevMode()),
      switchMap(() => this.store.select(isAuthenticated)),
      filter((authenticated) => authenticated),
      switchMap(() => this.store.select(selectPayload)),
      map((payload) => payload.exp * 1000),
      filter((exp) => new Date() > new Date(exp)),
      tap(() =>
        this.store.dispatch(AuthApiActions.userAuthenticationExpired({}))
      )
    );
  }
}
